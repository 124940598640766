import { useEffect } from "react";
import { EltrpPage } from "../components/wrappers/eltrp-page";
import { EltrpHeader } from "../components/wrappers/eltrp-header";
import { EltrpBody } from "../components/wrappers/eltrp-body";
import { EltrpText } from "../components/wrappers/eltrp-text";

export const DataPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <EltrpPage>
      <EltrpHeader title={" ELTRP data protection policy"} />
      <EltrpBody>
        <EltrpText>
          <h2>Processing of personal data at ELTRP</h2>
          <p>
            The Swedish tyre recycling association (“SDAB”) recognizes the
            importance of data protection and privacy of personal data of
            website visitors and will, for this reason, treat personal data with
            due diligence and in accordance with the General Data Protection
            Regulation (GDPR).
          </p>
          <h3>What information do we collect</h3>
          <p>
            The information we collect is general data provided directly by the
            user, namely user’s IP address, date and access time and the pages
            visited. We also collect a so called user agent, containing
            information on the user’s browser and operating system.
          </p>
          <h3>How do we use the information</h3>
          <p>
            The information collected is used solely for the purpose of
            technical administration of the web site and to help us managing any
            technical disturbances/problems during visitor’s use of the pages.
          </p>
          <h3>How do we use cookies</h3>
          <p> We do not use cookies. </p>
          <h3> How do we use local storage </h3>
          <p>
            Local storage on the browser is used to save articles on the site.
            They are stored until the browser history or storage is cleared.
          </p>
          <h3>How do we use publicly available data? </h3>
          <p>
            We process personal data relating to authors that is publicly
            available and of relevance to the portal. Information may be
            obtained from publicly available sources, such as information on the
            published work itself, or any other references of the author related
            to the publication.
          </p>
          <p>
            The purpose of processing publicly available data is to provide easy
            access to research information relevant for the recycling of rubber.
            Our legal grounds for processing such publicly available personal
            data is Art. 6 (1) (f) of the GDPR. We have a legitimate interest to
            provide an accurate and comprehensive collection of links to
            scientific work, which cannot be achieved without processing of this
            publicly available data. We are not able to ensure that the publicly
            available personal data has been processed in accordance with
            applicable data protection laws. Should you notify us that your
            personal data provided in the publicly available data is incorrect,
            or has not been processed in accordance with the applicable data
            protection laws, we may delete it upon verification of your
            identity.
          </p>
          <h3>Where do we process and store collected data</h3>
          <p>All data collected is processed and stored within the EEA.</p>
          <h3>Your rights</h3>
          <p>
            You have the possibility to exercise the rights described in the
            GDPR at all times.
          </p>
          <ul>
            <li>
              <b>Right of subject access. </b> The right to make a written
              request for details of personal information and a copy of that
              personal information.
            </li>
            <li>
              <b>Right to rectification.</b> The right to have inaccurate
              personal data rectified or completed.
            </li>
            <li>
              <b> Right to be forgotten.</b> The right to have certain personal
              information erased.
            </li>
            <li>
              <b>Right to restriction of processing. </b> The right to request
              that some personal information is only used for restricted
              purposes.
            </li>
            <li>
              <b> Right to data portability.</b> The right to request your
              personal information to be transferred to you or a third party in
              machine-readable format.{" "}
            </li>
            <li>
              <b> Right to lodge a complaint.</b> The right to lodge a complaint
              to the Swedish Authority for Privacy Protection&#39;s (IMY).
            </li>
          </ul>
          <h3>Contact information to the Data Protection Officer</h3>
          <p>
            If you wish to lodge a complaint to the IMY contact details are:
            <br /> <br />
            Integritetsskyddsmyndigheten, Box 8114, 104 20 Stockholm, Sweden
            <br />
            +46 (0)8 657 61 00 <br />
            E-mail: <a href={"mailto: imy@imy.se"}> imy@imy.se</a>. <br />{" "}
            <br />
            If you wish to invoke the above rights you can do so by contacting
            SDAB at e-mail <a href={"mailto: info@eltrp.org"}>info@eltrp.org</a>
            .
          </p>
          <h3>Changes to this Data Protection Policy</h3>
          <p>
            SDAB reserves the right to modify and update this Data Protection
            Policy at any time by posting a new version on our website.
          </p>
        </EltrpText>
      </EltrpBody>
    </EltrpPage>
  );
};