import { httpClient } from "../utils/http/httpClient";
import { API } from "../environment/api";
import { Aggregations } from "./Aggregation";
import { catchError, map, throwError } from "rxjs";
import { ErrorMap } from "../article/article.service";

export function getArticlesCSV(
  setError: (err: ErrorMap) => void,
  setLoading: (val: boolean) => void,
) {
  return httpClient.get(API.qualityControl.csv.articles).pipe(
    map((res) => res.data),
    catchError((err) => {
      setError({
        statusCode: err.statusCode,
        message: err.message,
        extra: err.extra,
      });
      setLoading(false);
      return throwError(err);
    }),
  );
}

export function getAggregationsCSV(
  agg: Aggregations,
  setError: (err: ErrorMap) => void,
  setLoading: (val: boolean) => void,
) {
  return httpClient
    .get(API.qualityControl.csv.aggregations, {
      pathParams: { agg },
    })
    .pipe(
      map((res) => res.data),
      catchError((err) => {
        setError({
          statusCode: err.statusCode,
          message: err.message,
          extra: err.extra,
        });
        setLoading(false);
        return throwError(err);
      }),
    );
}

export function getLinksCSV(
  setError: (err: ErrorMap) => void,
  setLoading: (val: boolean) => void,
) {
  return httpClient.get(API.qualityControl.csv.links).pipe(
    map((res) => res.data),
    catchError((err) => {
      setError({
        statusCode: err.statusCode,
        message: err.message,
        extra: err.extra,
      });
      setLoading(false);
      return throwError(err);
    }),
  );
}
