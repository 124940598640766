export class SavedArticles {
  public numSaves: number = 0;
  public lastSaved: Date = new Date();
  public articles: SavedArticle[] = [];

  public constructor(jsonString?: string) {
    if (jsonString) {
      const item = JSON.parse(jsonString) as SavedArticles;
      this.numSaves = item.numSaves;
      this.lastSaved = item.lastSaved;
      this.articles = item.articles;
    }
  }

  public addArticle = (article: SavedArticle) => {
    this.articles.push(article);
    this.update();
  };

  public removeArticle = (article: SavedArticle) => {
    this.articles = this.articles.filter(
      (a) => a.articleId !== article.articleId,
    );
    this.update();
  };

  public getIds = () => {
    return this.articles.map((a) => a.articleId);
  };

  public hasArticle = (articleId: string) => {
    const searchResult = this.articles.find((a) => a.articleId === articleId);
    return !!searchResult;
  };

  private update() {
    this.numSaves = this.articles.length;
    this.lastSaved = new Date();
  }
}

export interface SavedArticle {
  articleId: string;
  //tags: string[];
}
