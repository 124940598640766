import { EltrpModal } from "../components/modal/eltrp-modal";
import { Box, Divider, MenuItem, Select } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Aggregations } from "./Aggregation";
import { useContext, useState } from "react";
import {
  getAggregationsCSV,
  getArticlesCSV,
  getLinksCSV,
} from "./quality-control.service";
import { UserContext } from "../utils/context/user-context";
import { LoadingButton } from "../components/buttons/loading-button";

export const QualityControlBox = ({ open, setOpen }: any) => {
  const [agg, setAgg] = useState<Aggregations>();
  const [loadingArticle, setLoadingArticle] = useState<boolean>(false);
  const [loadingAgg, setLoadingAgg] = useState<boolean>(false);
  const [loadingLink, setLoadingLink] = useState<boolean>(false);

  const context = useContext(UserContext);

  const download = (data: string, filename: string) => {
    const blob = new Blob([data], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
  };
  const generateFilename = (filename: string) => {
    return `${filename}_${Date.now()}.csv`;
  };

  const getArticlesQC = () => {
    setLoadingArticle(true);
    getArticlesCSV(context.handleError, setLoadingArticle).subscribe((res) => {
      const filename = generateFilename("articles");
      download(res, filename);
      setLoadingArticle(false);
    });
  };

  const getAggregationsQC = () => {
    if (agg) {
      setLoadingAgg(true);
      getAggregationsCSV(agg, context.handleError, setLoadingAgg).subscribe(
        (res) => {
          const filename = generateFilename(agg);
          download(res, filename);
          setLoadingAgg(false);
        },
      );
    }
  };

  const getLinksQC = () => {
    setLoadingLink(true);
    getLinksCSV(context.handleError, setLoadingLink).subscribe((res) => {
      const filename = generateFilename("links");
      download(res, filename);
      setLoadingLink(false);
    });
  };

  return (
    <EltrpModal
      title={"Quality Control"}
      open={open}
      handleClose={() => setOpen(false)}
    >
      <Grid2 container gap={1} textAlign={"center"}>
        <Grid2>
          Download quality control files for ELTRP articles. Includes both
          published and unpublished articles. <br />
        </Grid2>
        <Grid2 xs={12} p={1}>
          <Divider>
            {" "}
            <b> ARTICLES AND METADATA</b>
          </Divider>
          Contains metadata and properties information for articles.
          <Box mt={1}>
            <LoadingButton
              title={"DOWNLOAD"}
              loading={loadingArticle}
              onClick={getArticlesQC}
            />
          </Box>
        </Grid2>
        <Grid2 xs={12} p={1}>
          <Divider>
            {" "}
            <b>AGGREGATIONS </b>{" "}
          </Divider>
          Contains fields and count for selected aggregation. Select the
          aggregation from the list below.
          <Box mt={1}>
            <form>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={2}
              >
                <Select
                  sx={{ padding: 0, minWidth: 140 }}
                  color={"secondary"}
                  size={"small"}
                  value={agg || ""}
                  onChange={(val) => setAgg(val.target.value as Aggregations)}
                >
                  {Object.entries(Aggregations).map((aggs) => {
                    return (
                      <MenuItem value={aggs[0]} key={aggs[0]}>
                        {" "}
                        {aggs[1]}{" "}
                      </MenuItem>
                    );
                  })}
                </Select>
                <LoadingButton
                  title={"DOWNLOAD"}
                  loading={loadingAgg}
                  onClick={getAggregationsQC}
                  disabled={!agg}
                />
              </Box>
            </form>
          </Box>
        </Grid2>
        <Grid2 xs={12} p={1}>
          <Divider>
            {" "}
            <b> LINKS</b>
          </Divider>
          Contains links for each access type and article.
          <Box mt={1}>
            <LoadingButton
              title={"DOWNLOAD"}
              loading={loadingLink}
              onClick={getLinksQC}
            />
          </Box>
        </Grid2>
      </Grid2>
    </EltrpModal>
  );
};
