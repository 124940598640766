import { Aggregation } from "../../models/Aggregation";
import Grid2 from "@mui/material/Unstable_Grid2";
import { DesktopAggregations } from "./desktop-aggregations";
import { MobileAggregations } from "./mobile-aggregations";

export interface IAggregationsProps {
  aggregations: Aggregation[];
}

export const AggregationsList = ({ aggregations }: IAggregationsProps) => {
  return (
    <>
      <Grid2 container>
        <Grid2 sx={{ display: { xs: "none", md: "inline" } }}>
          <DesktopAggregations aggregations={aggregations} />
        </Grid2>

        <Grid2 sx={{ display: { xs: "flex", md: "none" } }}>
          <MobileAggregations aggregations={aggregations} />
        </Grid2>
      </Grid2>
    </>
  );
};
