import { useEffect, useState } from "react";
import { IAutocomplete } from "../../../models/Autocomplete";
import { useDebounce } from "../../../utils/hooks/debounce";
import { getAutocomplete } from "../../article.service";

export const Options = (
  name: string,
  active: any,
  query: string,
  lead?: string,
) => {
  const [autocomplete, setAutocomplete] = useState<IAutocomplete>();
  const debouncedQuery = useDebounce(query, 300);

  useEffect(() => {
    if (active) {
      getAutocomplete(name, debouncedQuery, lead).subscribe((a) => {
        setAutocomplete(a);
      });
    }
  }, [debouncedQuery, name, lead, active]);

  return autocomplete?.buckets.map((bucket) => bucket.key) || [];
};
