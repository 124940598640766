import { httpClient } from "../../utils/http/httpClient";
import { API } from "../../environment/api";
import { catchError, map, throwError } from "rxjs";
import { SearchResponse } from "../../models/SearchResult";
import { ErrorMap } from "../../article/article.service";

export function updateBasket(ids: string[], setError: (err: ErrorMap) => void) {
  return httpClient.post(API.basket.update, { ids: ids }, {}).pipe(
    map((res) => res.data),
    catchError((err) => {
      setError({
        statusCode: err.statusCode,
        message: err.message,
        extra: err.extra,
      });
      return throwError(err);
    }),
  );
}

export function getBasket(
  basketId: string,
  filters: string[] | [],
  setError: (err: ErrorMap) => void,
  setLoading: (val: boolean) => void,
  from?: string,
  size?: string,
) {
  return httpClient
    .get(API.basket.get, {
      pathParams: { basketId },
      queryParams: {
        from,
        size,
        filter: [...filters, "dummyvalue"],
      },
    })
    .pipe(
      map((res) => res.data as SearchResponse),
      catchError((err) => {
        setError({
          statusCode: err.statusCode,
          message: err.message,
          extra: err.extra,
        });
        setLoading(false);
        return throwError(err);
      }),
    );
}

export function getSavedArticles(
  ids: string[],
  filters: string[] | [],
  setError: (err: ErrorMap) => void,
  setLoading: (val: boolean) => void,
  from?: string,
  size?: string,
) {
  return httpClient
    .post(
      API.basket.show,
      { ids: ids },
      {
        queryParams: {
          from,
          size,
          filter: [...filters, "dummyvalue"],
        },
      },
    )
    .pipe(
      map((res) => res.data as SearchResponse),
      catchError((err) => {
        setError({
          statusCode: err.statusCode,
          message: err.message,
          extra: err.extra,
        });
        setLoading(false);
        return throwError(err);
      }),
    );
}
