import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SearchPage } from "./search/search-page";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ArticlePage } from "./article/article-page";
import { ArticleEditPage } from "./article/edit/article-edit-page";
import { LoginModal } from "./login/login-modal";
import { AdminBar } from "./components/admin/admin-bar";
import { LandingPage } from "./landingpage/landing-page";
import { Dictionary } from "./dictionary/dictionary";
import { ErrorBar } from "./components/error/error-bar";
import { Copyright } from "./link-pages/copyright";
import { DataPolicy } from "./link-pages/data-policy";
import { HowToSearch } from "./link-pages/how-to-search";
import { About } from "./link-pages/about";
import { theme } from "./utils/theme";
import { UserProvider } from "./utils/context/user-context";
import { PrintablePage } from "./components/print/printable-page";
import { NewsletterPage } from "./newsletter/newsletter-page";
import { Contact } from "./link-pages/contact";
import { BasketPage } from "./basket/basket-page";
import { FavoritesPage } from "./basket/favorites-page";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <UserProvider>
          <LoginModal />
          <AdminBar />
          <ErrorBar />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path={"/search"} element={<SearchPage />} />
            <Route path={"/article/:id"} element={<ArticlePage />} />
            <Route path={"/article/:id/edit"} element={<ArticleEditPage />} />
            <Route path="/dictionary" element={<Dictionary />} />
            <Route path="/copyright" element={<Copyright />} />
            <Route path="/data-policy" element={<DataPolicy />} />
            <Route path="/how-to-search" element={<HowToSearch />} />
            <Route path="/about" element={<About />} />
            <Route path={"/article/:id/print"} element={<PrintablePage />} />
            <Route path={"/newsletter"} element={<NewsletterPage />} />
            <Route path={"/contact"} element={<Contact />} />
            <Route path={"/favourites"} element={<FavoritesPage />} />
            <Route path={"/favourites/:basketId"} element={<BasketPage />} />
          </Routes>
        </UserProvider>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
);
