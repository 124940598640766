import { EltrpPage } from "../components/wrappers/eltrp-page";
import { EltrpHeader } from "../components/wrappers/eltrp-header";
import { EltrpBody } from "../components/wrappers/eltrp-body";
import { EltrpText } from "../components/wrappers/eltrp-text";
import { NewsletterForm } from "./newsletter-form";
import { useEffect } from "react";

export const NewsletterPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <EltrpPage>
      <EltrpHeader title={"ELTRP Newsletter"} />
      <EltrpBody>
        <EltrpText>
          <h2> Subscribe to the ELTRP newsletter!</h2>
          <p>
            The ELTRP newsletter contains news and the latest reports. Fill in
            the form below to subscribe to the newsletter. You will get a
            confirmation to the email used in the form. If you don't get a
            confirmation, check your spam folder or contact SDAB.
          </p>
          <h3> Newsletter Archive </h3>
          <p>
            All previous newsletter are available through our{" "}
            <a
              href={
                "https://ui.ungpd.com/Issues/Archive/Categories/fd85cc73-fae2-4d0d-997f-3f9557d5586a"
              }
              target={"_blank"}
              rel={"noreferrer"}
            >
              newsletter archive!
            </a>{" "}
            Visit it to catch up on old newsletter and don't forget to subscribe
            for new newsletters.
          </p>
        </EltrpText>
        <EltrpText>
          <NewsletterForm />
        </EltrpText>
      </EltrpBody>
    </EltrpPage>
  );
};