import { Article } from "../models/Article";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { ShareArticle } from "../components/share/share-article";
import { SaveArticle } from "../components/save/save-article";
import { Public, PublicOff } from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useContext } from "react";
import { UserContext } from "../utils/context/user-context";

export const ResultBox = (props: {
  results?: Article[];
  sharable?: boolean;
  exclude?: string;
  forNewsletter?: boolean;
}) => {
  const context = useContext(UserContext);
  return (
    <Box id={"searchResultList"}>
      {props.results
        ?.filter((r) => r._id !== props.exclude)
        .filter((r) =>
          props.forNewsletter ? r.metadata?.published === true : true,
        )
        .map((r) => (
          <Box mb={4} key={r._id}>
            <Grid2 container xs={12} key={r._id}>
              <Grid2 xs={12} lg={8}>
                <Grid2 xs={12}>
                  <Link to={"/article/" + r._id}>
                    <h3 style={{ fontSize: "1.4rem", margin: 0 }}>
                      {r.metadata?.titleEn ? r.metadata.titleEn : r._id}
                    </h3>
                  </Link>
                </Grid2>
                <Grid2
                  display={"flex"}
                  justifyContent={{ lg: "right" }}
                  xs={12}
                  lg={4}
                  xl={3.5}
                >
                  <Box display={{ xs: "flex", lg: "none" }} gap={{ xs: 2 }}>
                    <SaveArticle articleId={r._id} />
                    <ShareArticle articleId={r._id} />
                  </Box>
                </Grid2>
                <Grid2 xs={12}>
                  <Typography variant={"subtitle1"}>
                    {!!r.metadata?.organization?.length &&
                      r.metadata?.organization
                        ?.map((e) => e[0] + (e[1] ? ", " + e[1] : ""))
                        .join("; ")}
                  </Typography>
                </Grid2>
                <Grid2 xs={12}>
                  {!!r.metadata?.author?.length && (
                    <Typography variant={"subtitle2"}>
                      {" "}
                      {r.metadata?.author?.join("; ")}{" "}
                    </Typography>
                  )}
                </Grid2>
                <Grid2
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  xs={12}
                >
                  <Typography variant={"subtitle2"}>
                    {" "}
                    {(r.metadata?.type ? r.metadata.type : "") +
                      (r.metadata?.year ? ", " + r.metadata.year : "")}{" "}
                  </Typography>{" "}
                </Grid2>
              </Grid2>
              <Grid2 display={{ xs: "none", lg: "inline" }} lg={4}>
                <Box
                  display={"flex"}
                  flexDirection={{ lg: "column" }}
                  textAlign={"right"}
                  justifyContent={"right"}
                >
                  <ShareArticle articleId={r._id} />
                  <SaveArticle articleId={r._id} />
                  {context.env === "ADMIN" &&
                    (r?.metadata?.published ? (
                      <Typography variant={"subtitle2"} color={"secondary"}>
                        <Box
                          display={"flex"}
                          gap={1}
                          alignItems={"center"}
                          justifyContent={"right"}
                        >
                          {" "}
                          <Public fontSize={"small"} /> PUBLISHED{" "}
                        </Box>
                      </Typography>
                    ) : (
                      <Typography variant={"subtitle2"} color={"secondary"}>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"right"}
                          gap={1}
                        >
                          <PublicOff fontSize={"small"} /> NOT PUBLISHED
                        </Box>
                      </Typography>
                    ))}
                </Box>
              </Grid2>
            </Grid2>
          </Box>
        ))}
    </Box>
  );
};