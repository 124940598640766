import Grid2 from "@mui/material/Unstable_Grid2";
import { AggregationList } from "./aggregation-list";
import { IAggregationsProps } from "./aggregations";
import { fieldNameMapping } from "./field-name-mapping";
import { Box } from "@mui/material";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { ClearAggregations } from "./clear-aggregations";

export const DesktopAggregations = ({ aggregations }: IAggregationsProps) => {
  const [searchParams] = useSearchParams();

  const numAggs = useMemo(() => {
    let num = 0;
    aggregations.forEach((agg) => {
      num += searchParams.getAll(agg.field).length;
    });
    return num;
  }, [searchParams, aggregations]);

  const sortedAggregations = aggregations.sort(
    (a, b) =>
      fieldNameMapping[a.field].importance -
      fieldNameMapping[b.field].importance,
  );

  return (
    <>
      <Grid2 container flexDirection={"column"}>
        <h2> Aggregations {!!numAggs && `(${numAggs})`} </h2>
        <i> Refine the result </i>

        <Box mt={3}>
          {!!numAggs && (
            <Box width={"250px"}>
              <ClearAggregations
                aggregations={aggregations}
                variant={"contained"}
              />
            </Box>
          )}
          <ul className="searchAggregation">
            {sortedAggregations
              .filter((a) => !(a.buckets?.length < 1))
              .map((a) => (
                <AggregationList key={a.field} aggregation={a} />
              ))}
          </ul>
        </Box>
      </Grid2>
    </>
  );
};
