import { Badge, Box, IconButton } from "@mui/material";
import { fieldNameMapping } from "./field-name-mapping";
import { AggregationList } from "./aggregation-list";
import { IAggregationsProps } from "./aggregations";
import { useMemo, useState } from "react";
import { EltrpSlider } from "../../components/animations/eltrp-slider";
import { Tune } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import { ClearAggregations } from "./clear-aggregations";

export const MobileAggregations = ({ aggregations }: IAggregationsProps) => {
  const [searchParams] = useSearchParams();
  const [open, setOpen] = useState<boolean>(false);

  const numAggs = useMemo(() => {
    let num = 0;
    aggregations.forEach((agg) => {
      num += searchParams.getAll(agg.field).length;
    });
    return num;
  }, [searchParams, aggregations]);

  const sortedAggregations = aggregations.sort(
    (a, b) =>
      fieldNameMapping[a.field].importance -
      fieldNameMapping[b.field].importance,
  );

  return (
    <Box>
      <IconButton onClick={() => setOpen(true)}>
        <Badge badgeContent={numAggs}>
          <Tune />
        </Badge>
      </IconButton>

      <EltrpSlider
        title={"AGGREGATIONS"}
        open={open}
        close={() => setOpen(false)}
      >
        <Box display={"flex"} ml={2} mt={2} mr={2}>
          {!!numAggs && (
            <ClearAggregations
              aggregations={aggregations}
              variant={"contained"}
            />
          )}
        </Box>
        <Box p={1}>
          <ul className="searchAggregationSmall">
            {sortedAggregations
              .filter((a) => !(a.buckets?.length < 1))
              .map((a) => (
                <AggregationList key={a.field} aggregation={a} />
              ))}
          </ul>
        </Box>
      </EltrpSlider>
    </Box>
  );
};
