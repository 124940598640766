import {
  Add,
  ChangeCircle,
  Edit,
  Login,
  Logout,
  MailOutline,
  Verified,
} from "@mui/icons-material";
import { AppBar, Box, Button, Divider, Toolbar } from "@mui/material";
import { useContext, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PdfUploadBox } from "../../pdf/pdf-upload";
import { ACCESS_TOKEN, REFRESH_TOKEN, revoke } from "../../auth/auth.service";
import { UserContext } from "../../utils/context/user-context";
import { QualityControlBox } from "../../quality-control/quality-control-box";
import { NewsletterBox } from "../../newsletter/newsletter-box";
import { GlobalChangeBox } from "../../global-change/global-change-box";

export const AdminBar = () => {
  const context = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState<boolean>(false);
  const [openQC, setOpenQC] = useState(false);
  const [openGC, setOpenGC] = useState(false);

  const [openNL, setOpenNL] = useState(false);
  const loggedIn = useMemo(() => context.isAuth, [context]);

  const logout = () => {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    if (refreshToken) {
      revoke(refreshToken, () => {}).subscribe(() => {});
    }
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    context.dispatch(true);
  };

  const login = () => {
    context.dispatch(true);
  };

  return (
    <Box display={context.env === "ADMIN" ? "box" : "none"}>
      <AppBar
        className="adminBar"
        color="secondary"
        position="static"
        style={{ padding: "0%", backgroundColor: "white", boxShadow: "none" }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box
            display={
              loggedIn && !location.pathname.includes("edit") ? "flex" : "none"
            }
            flexGrow={1}
            gap={2}
          >
            <Button
              startIcon={<Add />}
              color="secondary"
              size={"small"}
              onClick={() => setOpen(true)}
            >
              Create New Article
            </Button>
            <Box
              display={
                location.pathname.includes("article") &&
                !location.pathname.includes("edit")
                  ? "flex"
                  : "none"
              }
            >
              <Button
                startIcon={<Edit />}
                color="secondary"
                onClick={() => navigate(location.pathname + "/edit")}
                size="small"
              >
                Edit article
              </Button>
            </Box>
            <Button
              startIcon={<Verified />}
              color={"secondary"}
              size={"small"}
              onClick={() => setOpenQC(true)}
            >
              Quality Control
            </Button>
            <Button
              startIcon={<ChangeCircle />}
              color={"secondary"}
              size={"small"}
              onClick={() => setOpenGC(true)}
            >
              Global Change
            </Button>
            <Button
              startIcon={<MailOutline />}
              color={"secondary"}
              size={"small"}
              onClick={() => setOpenNL(true)}
            >
              Newsletter{" "}
            </Button>
          </Box>
          <Box display={loggedIn ? "flex" : "none"}>
            <Button
              startIcon={<Logout />}
              color="secondary"
              onClick={logout}
              size="small"
            >
              Logout
            </Button>
          </Box>
          <Box display={loggedIn ? "none" : "flex"} flexBasis="1">
            <Button
              startIcon={<Login />}
              color="secondary"
              onClick={login}
              size="small"
            >
              Login
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Divider flexItem />
      <PdfUploadBox open={open} setOpen={setOpen} />
      <QualityControlBox open={openQC} setOpen={setOpenQC} />
      <GlobalChangeBox open={openGC} setOpen={setOpenGC} />
      <NewsletterBox open={openNL} setOpen={setOpenNL} />
    </Box>
  );
};
