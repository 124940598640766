import { Article } from "../models/Article";

export const ResultList = (props: {
  results?: Article[];
  forNewsletter?: boolean;
}) => {
  return (
    <div id={"searchResultList"}>
      {props.results
        ?.filter((r) =>
          props.forNewsletter ? r.metadata?.published === true : true,
        )
        .map((r) => (
          <div key={r._id}>
            <div>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <a href={"https://eltrp.org/article/" + r._id}>
                    <h3 style={{ fontSize: 20, margin: 0 }}>
                      {r.metadata?.titleEn ? r.metadata.titleEn : r._id}
                    </h3>
                  </a>
                </li>
                {!!r.metadata?.organization?.length && (
                  <li>
                    {r.metadata?.organization
                      ?.map((e) => e[0] + (e[1] ? ", " + e[1] : ""))
                      .join("; ")}
                  </li>
                )}
                {!!r.metadata?.author?.length && (
                  <li>
                    {" "}
                    <small> {r.metadata?.author?.join("; ")} </small>
                  </li>
                )}
                <li>
                  {" "}
                  <small>
                    {" "}
                    {(r.metadata?.type ? r.metadata.type : "") +
                      (r.metadata?.year ? ", " + r.metadata.year : "")}{" "}
                  </small>{" "}
                </li>
              </ul>
            </div>
          </div>
        ))}
    </div>
  );
};
