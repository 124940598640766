import { useSearchParams } from "react-router-dom";
import { IAggregationsProps } from "./aggregations";
import { DeleteForever } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";

interface IClearAggregationProps extends IAggregationsProps, ButtonProps {}

export const ClearAggregations = (props: IClearAggregationProps) => {
  const { aggregations, variant } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const selected: [string[]] = [[]];
  aggregations?.forEach((aggregation) =>
    selected.push(searchParams.getAll(aggregation.field)),
  );

  const deleteAggregations = () => {
    const filter: string[] = [];
    const size = searchParams.get("size");
    const query = searchParams.get("query");
    searchParams.forEach((k, v) => filter.push(v));

    for (const f of filter) {
      searchParams.delete(f);
    }

    searchParams.set("size", size || "10");
    query && searchParams.set("query", query || "");
    searchParams.set("from", "0");

    setSearchParams(searchParams);
  };

  return (
    <Button
      variant={variant}
      color={"secondary"}
      sx={{ width: "min(91%, 350px)" }}
      startIcon={<DeleteForever />}
      onClick={deleteAggregations}
    >
      Clear Aggregations
    </Button>
  );
};
