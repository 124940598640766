import React, { Dispatch, useEffect, useMemo, useState } from "react";
import { ErrorMap } from "../../article/article.service";
import { getAuth, getEnv } from "../../auth/auth.service";

export const UserContext = React.createContext({
  isAuth: false,
  env: "",
  authRequired: false,
  snackbar: {
    message: "",
    extra: null,
    statusText: "",
    open: false,
    persistent: false,
  },
  handleSnackbar: (() => null) as Dispatch<any>,
  dispatch: (() => null) as Dispatch<any>,
  handleError: (error: ErrorMap) => {},
});

export const UserProvider = ({ children }: any) => {
  const [isAuth, setIsAuth] = useState(true);
  const [env, setEnv] = useState("");
  const [authRequired, dispatch] = React.useReducer(
    (state: any, newValue: any) => ({ ...state, ...newValue }),
    false,
  );
  const [snackbar, handleSnackbar] = React.useReducer(
    (state: any, newValue: any) => ({ ...state, ...newValue }),
    {
      message: "",
      extra: null,
      statusText: "",
      open: false,
      persistent: false,
    },
  );

  useEffect(() => {
    getEnv().subscribe((r) => setEnv(r.data.env));
  }, []);

  useEffect(() => {
    if (env === "ADMIN") {
      getAuth(setIsAuth).subscribe((r) =>
        r.status === 200 ? setIsAuth(true) : setIsAuth(false),
      );
    }
  }, [env, authRequired]);

  useEffect(() => {
    if (authRequired) {
      setIsAuth(false);
    }
  }, [authRequired]);

  const handleError = useMemo(
    () => (error: ErrorMap) => {
      if (error.statusCode === 401) {
        setIsAuth(false);
      }
      if (error.statusCode !== 401) {
        handleSnackbar({
          message: error.message,
          extra: error.extra,
          statusText: error.statusText,
          open: true,
          persistent: error.persistent,
        });
      }
    },
    [],
  );

  return (
    <UserContext.Provider
      value={{
        isAuth,
        env,
        dispatch,
        handleError,
        authRequired,
        snackbar,
        handleSnackbar,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
