import Grid2 from "@mui/material/Unstable_Grid2";
import { Link } from "react-router-dom";
import { FeedOutlined, MenuBookOutlined } from "@mui/icons-material";
import { Divider, Typography } from "@mui/material";
import { httpClient } from "../utils/http/httpClient";
import { useEffect, useState } from "react";
import { EltrpPage } from "../components/wrappers/eltrp-page";
import { EltrpBody } from "../components/wrappers/eltrp-body";
import { EltrpText } from "../components/wrappers/eltrp-text";
import { SearchBoxLanding } from "./search-box-landing";

export const LandingPage = () => {
  const [imageURL, setImageURL] = useState<string>();

  useEffect(() => {
    httpClient
      .get("/api/v1/data/worldmap", {
        responseType: "blob",
        authenticated: true,
      })
      .subscribe((res) => {
        setImageURL(URL.createObjectURL(res.data));
      });
  }, []);
  return (
    <EltrpPage>
      <div style={{ height: "4px" }}></div>
      <section className="landingPageSearch">
        <SearchBoxLanding />
      </section>
      <section className="landingPageLinks">
        <Grid2
          container
          gap={{ xs: 0, sm: 5 }}
          justifyContent={{ xs: "space-evenly", sm: "center" }}
          alignItems="center"
          marginTop="1rem"
          marginBottom="1rem"
        >
          <Grid2 xs={5.5} sm={2.5} textAlign={"center"}>
            <Link to={"/search/?sortOrder=-PUBDATE&from=0&size=10"}>
              <FeedOutlined
                color="secondary"
                fontSize="large"
                sx={{ stroke: "#ffffff", strokeWidth: 0.5 }}
              />{" "}
              <br />
              <Typography variant={"button"}> Latest Publications </Typography>
            </Link>
          </Grid2>
          <Divider flexItem orientation="vertical" />
          <Grid2 xs={5.5} sm={2.5} textAlign={"center"}>
            <Link to={"/dictionary"}>
              <MenuBookOutlined
                color="secondary"
                fontSize="large"
                sx={{ stroke: "#ffffff", strokeWidth: 0.5 }}
              />{" "}
              <br />
              <Typography variant={"button"}> Dictionary </Typography>{" "}
            </Link>
          </Grid2>
        </Grid2>
      </section>
      <Divider flexItem />
      <EltrpBody>
        <Grid2>
          <EltrpText>
            <h2> Welcome to End-of-life Tyre Research Portal (ELTRP) </h2>
            <p>
              {" "}
              ELTRP, the End-of-life Tyre Research Portal, brings together
              articles and reports about recycled tyre rubber. It is a platform
              that aims to support society by enabling access to research into
              recycled tyre rubber. The ELTRP is open to everyone, providing
              reference material for innovators and businesses as well as
              authorities, municipalities and legislators. The portal also
              contains a glossary explaining terminology and concepts commonly
              associated with research into recycled tyre rubber.
            </p>
            <Grid2 textAlign={"center"}>
              <h3>
                {" "}
                Geographical overview of research on recycled tyre rubber{" "}
              </h3>
              <img
                id="imageId"
                alt="world-map"
                src={imageURL}
                width={"100%"}
                style={{ maxWidth: "1000px" }}
              />
            </Grid2>
          </EltrpText>
        </Grid2>
      </EltrpBody>
    </EltrpPage>
  );
};