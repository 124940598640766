import { useContext, useEffect, useState } from "react";
import { Star, StarOutline } from "@mui/icons-material";
import { ActionButton } from "../buttons/action-button";
import { Box } from "@mui/material";
import { EltrpModal } from "../modal/eltrp-modal";
import { Link } from "react-router-dom";
import { UserContext } from "../../utils/context/user-context";
import {
  addToLocalStorage,
  hasLocalStorage,
  isInLocalStorage,
  removeFromLocalStorage,
} from "../../utils/storage/local-storage";

interface ISaveArticle {
  articleId?: string;
}

export const SaveArticle = (props: ISaveArticle) => {
  const { articleId } = props;

  const [saved, setSaved] = useState(
    articleId ? isInLocalStorage(articleId) : false,
  );
  const [popOpen, setPopOpen] = useState(false);

  const context = useContext(UserContext);

  useEffect(() => {
    articleId && setSaved(isInLocalStorage(articleId));
  }, [articleId]);

  const saveArticle = () => {
    if (!hasLocalStorage()) {
      setPopOpen(true);
    }
    if (!saved && articleId) {
      try {
        addToLocalStorage({ articleId: articleId });
      } catch (err) {
        context.handleError({
          message: "Error saving article",
          statusCode: 418,
          extra: "Max limit reached",
        });
      }
    }
    if (saved && articleId) {
      removeFromLocalStorage({ articleId: articleId });
    }

    articleId && setSaved(isInLocalStorage(articleId));
  };

  return (
    <Box>
      <ActionButton
        title={saved ? "Saved as favourite" : "Save as favourite"}
        startIcon={saved ? <Star /> : <StarOutline />}
        onClick={saveArticle}
      />
      <EltrpModal
        title={"Your first save!"}
        open={popOpen}
        handleClose={() => setPopOpen(false)}
      >
        <Box textAlign={"center"}>
          <p>
            {" "}
            Congratulations on your first saved article! You can save up to 100
            articles on the ELTRP page. You can find your saved articles and
            share them under
            <Link to={"/favourites"}> Favourite Articles</Link>. The articles
            are saved using local storage, read more about the use of local
            storage under{" "}
            <Link to={"/data-policy"}>ELTRP data protection policy</Link>.{" "}
          </p>
        </Box>
      </EltrpModal>
    </Box>
  );
};