import { httpClient } from "../utils/http/httpClient";
import { API } from "../environment/api";
import { catchError, throwError } from "rxjs";

export const ACCESS_TOKEN = "token";
export const REFRESH_TOKEN = "refreshToken";
export interface ISignInRequest {
  username: string;
  password: string;
}
export function signIn(
  data: ISignInRequest,
  setError: (err: string) => void,
  setLoading: (val: boolean) => void,
) {
  return httpClient.post(API.auth.login, data).pipe(
    catchError((err) => {
      setError(err.message);
      setLoading(false);
      return throwError(err);
    }),
  );
}

export function revoke(refreshToken: string, setError: (err: string) => void) {
  return httpClient.post(API.auth.revoke, { refreshToken }).pipe(
    catchError((err) => {
      setError(err.message);
      return throwError(err);
    }),
  );
}

export function getAuth(setIsAuth: (val: boolean) => void) {
  return httpClient.get(API.auth.auth, { authenticated: true }).pipe(
    catchError((err) => {
      err.response.status === 401 && setIsAuth(false);
      return throwError(err);
    }),
  );
}

export function getEnv() {
  return httpClient.get(API.env);
}
