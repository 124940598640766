import { httpClient } from "../utils/http/httpClient";
import { API } from "../environment/api";
import { catchError, map, throwError } from "rxjs";
import { ErrorMap } from "../article/article.service";

export interface IGlobalChange {
  index: string;
  term: string;
  value: string;
  lead?: string;
}

export interface IGlobalChangeResponse {
  docs_total: number;
  docs_processed: number;
  metadata_updated: number;
  metadata_noop: number;
  properties_updated: number;
}

export function globalChange(
  change: IGlobalChange,
  setError: (err: ErrorMap) => void,
  setLoading: (val: boolean) => void,
) {
  return httpClient.post(API.globalChange, change, {}).pipe(
    map((res) => res.data as IGlobalChangeResponse),
    catchError((err) => {
      setError({
        statusCode: err.response.status,
        message: err.message,
        statusText: err.response.statusText,
        extra: err.response.data.extra,
      });
      setLoading(false);
      return throwError(err);
    }),
  );
}
