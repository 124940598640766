import { Autocomplete, Box, InputProps, TextField } from "@mui/material";
import { Options } from "../article/edit/fields/options";

interface IGlobalChangeMultiAutocompleteProps extends InputProps {
  termId: string;
  active?: boolean;
  lead: string;
  input: any;
}

export const GlobalChangeMultiAutocomplete = (
  props: IGlobalChangeMultiAutocompleteProps,
) => {
  const onChange = (index: number) => (value: any) => {
    const updated = [...props.input.value];
    updated[index] = value;
    props.input.onChange(updated);
  };

  return (
    <Box mt={1}>
      <Autocomplete
        options={Options(props.lead, props.active, props.input.value?.[0])}
        renderInput={(params) => (
          <TextField
            color="secondary"
            label={"Organisation"}
            {...params}
            size="small"
            fullWidth
            onChange={(e) => onChange(0)(e.target.value)}
          />
        )}
        {...props.input}
        value={props.input.value[0] || ""}
        onChange={(event, value, reason) => onChange(0)(value)}
      />
      <Autocomplete
        options={Options(
          props.termId,
          props.active,
          props.input.value?.[1],
          props.input.value?.[0] || "",
        )}
        renderInput={(params) => (
          <TextField
            color="secondary"
            {...params}
            label={"Unit"}
            size="small"
            fullWidth
            onChange={(e) => onChange(1)(e.target.value)}
            margin="normal"
          />
        )}
        {...props.input}
        value={props.input.value[1] || ""}
        onChange={(event, value, reason) => onChange(1)(value)}
      />
    </Box>
  );
};
