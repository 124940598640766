import { Box } from "@mui/material";
import { MetadataLink } from "./metadata-link";
import { MetadataTypes } from "./metadata-types";

interface IMetadataLinkField {
  metadataList?: string[];
  metadataType: MetadataTypes;
}

export const MetadataLinkField = (props: IMetadataLinkField) => {
  const { metadataList, metadataType } = props;
  return (
    <>
      {metadataList?.map((metadata, index) => {
        return (
          <Box display={"flex"}>
            <MetadataLink metadata={metadata} metadataType={metadataType} />
            {index !== metadataList.length - 1 && <>;&nbsp;</>}
          </Box>
        );
      })}
    </>
  );
};
