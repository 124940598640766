import { SavedArticle, SavedArticles } from "../../models/SavedArticles";

const key = "ELTRP_favourites";

export const addToLocalStorage = (value: SavedArticle) => {
  const item = getFromLocalStorage();
  if (item.numSaves > 100) {
    throw new Error("Max limit reached");
  }
  item.addArticle(value);
  setToLocalStorage(item);
};

export const removeFromLocalStorage = (value: SavedArticle) => {
  const item = getFromLocalStorage();
  item.removeArticle(value);
  setToLocalStorage(item);
};

export const isInLocalStorage = (articleId: string) => {
  const item = getFromLocalStorage();
  return item.hasArticle(articleId);
};

export const hasLocalStorage = () => {
  return localStorage.getItem(key);
};

export const getIdsFromLocalStorage = () => {
  const item = getFromLocalStorage();
  return item.getIds();
};

const getFromLocalStorage = () => {
  const item = localStorage.getItem(key);
  if (item) {
    return new SavedArticles(item);
  }
  return new SavedArticles();
};

const setToLocalStorage = (value: SavedArticles) => {
  localStorage.setItem(key, JSON.stringify(value));
};
