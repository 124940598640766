import { useEffect } from "react";
import { EltrpPage } from "../components/wrappers/eltrp-page";
import { EltrpHeader } from "../components/wrappers/eltrp-header";
import { EltrpBody } from "../components/wrappers/eltrp-body";
import { EltrpText } from "../components/wrappers/eltrp-text";

export const HowToSearch = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <EltrpPage>
      <EltrpHeader title={"How to search"} />
      <EltrpBody>
        <EltrpText>
          <h2> Basic searching and filtering </h2>
          <p>
            {" "}
            Find research publications in ELTRP by entering one or many search
            words and click the search icon or press enter to get a list of the
            most relevant search hits. To the left (or top if on a mobile
            device) you will see some aggregations of the search hits (by Year,
            Access type, Author, Organisation etc) and by clicking the
            checkboxes in front of the aggregation categories you can filter
            accordingly and narrow your search result.
          </p>
          <p>
            {" "}
            ELTRP is designed for searching in English, but it is also possible
            to search in other languages, but then the hits are limited to
            content in that language. Some special English synonym words related
            to tyres are handled automatically (e.g. granulate/crumb and
            tyre/tire).
          </p>
          <h2> Advanced searching </h2>
          <p>
            {" "}
            By using search operators you can make more advanced searches:{" "}
          </p>
          <ul>
            <li>
              {" "}
              <b> rubber plastic </b> search for publications containing both
              (words) rubber and plastic (i.e. "rubber AND plastic")
            </li>
            <li>
              {" "}
              <b> rubber -plastic </b> search for publications containing rubber
              but not plastic (i.e. "rubber NOT plastic")
            </li>
            <li>
              {" "}
              <b> rubber | plastic </b> search for publications containing
              rubber or plastic, or both (i.e. "rubber OR plastic")
            </li>
            <li>
              {" "}
              <b> "rubber or plastic" </b> search for publications containing
              the phrase "rubber or plastic" (i.e. phrase searching)
            </li>
            <li>
              {" "}
              <b> rubber* </b> search for all words that begins with rubber
              (i.e. truncation)
            </li>
            <li>
              {" "}
              <b> (rubber | plastic) tyre </b> combine operators using
              parentheses (i.e. "(rubber OR plastic) AND tyre")
            </li>
          </ul>
        </EltrpText>
      </EltrpBody>
    </EltrpPage>
  );
};
