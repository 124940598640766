import { Box, Button } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getDictionary } from "./dictionary.service";

import { alphabet } from "../static/alphabet";
import { DictionaryElement } from "./dictionary-components/dictionary-element";
import { EltrpHeader } from "../components/wrappers/eltrp-header";
import { EltrpPage } from "../components/wrappers/eltrp-page";
import { EltrpBody } from "../components/wrappers/eltrp-body";
import { EltrpLoading } from "../components/wrappers/eltrp-loading";

export const Dictionary = () => {
  const [dictionaryJSON, setDictionaryJSON] = useState({});
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const state = location.state as any;

  useEffect(() => {
    setLoading(true);
    getDictionary(setLoading).subscribe((a) => {
      setLoading(false);
      setDictionaryJSON(a);
    });
  }, [state]);

  useEffect(() => {
    if (state !== null) {
      const s = document.getElementById(state.subject);
      s?.scrollIntoView({ block: "center" });
      window.history.replaceState({}, document.title);
    }
  }, [state, dictionaryJSON]);

  const onLetterClick = (e: any) => {
    const s = document.getElementById(e);
    s?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <EltrpPage>
      <EltrpLoading loading={loading} />
      <EltrpHeader title={"DICTIONARY"}>
        <Grid2 textAlign={"center"} className={"dictionaryHeader"} mb={4}>
          {alphabet.map((letter) => (
            <Box
              display={"inline-flex"}
              height={30}
              width={30}
              justifyContent="center"
              alignItems={"center"}
            >
              <p
                className={
                  dictionaryJSON &&
                  Object.keys(dictionaryJSON)
                    .map((m) => m[0])
                    .includes(letter)
                    ? "letterActive"
                    : "letterDisabled"
                }
                key={letter + "2"}
                onClick={() => onLetterClick(letter)}
              >
                {" "}
                {letter}{" "}
              </p>{" "}
            </Box>
          ))}
          <br />
        </Grid2>
      </EltrpHeader>
      <EltrpBody>
        <Grid2
          xs={12}
          container
          display="flex"
          justifyContent="center"
          className="dictionaryBody"
        >
          {Object.keys(dictionaryJSON)
            .map((m) => m[0])
            .filter((v, i, a) => a.indexOf(v) === i)
            .map((letter: string) => (
              <Grid2
                columnGap={5}
                rowGap={5}
                container
                justifyContent="center"
                key={letter}
                textAlign="center"
              >
                <DictionaryElement
                  letterT={letter}
                  dictionaryJSON={dictionaryJSON}
                />
              </Grid2>
            ))}
        </Grid2>
        <Grid2 xs={12} textAlign={"center"} mt={5} mb={5}>
          <Button
            color={"secondary"}
            variant={"outlined"}
            onClick={() =>
              document
                .getElementById("top")
                ?.scrollIntoView({ behavior: "smooth" })
            }
          >
            Return To Top
          </Button>
        </Grid2>
      </EltrpBody>
    </EltrpPage>
  );
};
