import { useContext, useEffect, useMemo, useState } from "react";
import { Article } from "../../models/Article";
import { getArticle, updateArticle } from "../article.service";
import { Field, Form } from "react-final-form";
import {
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  TextField,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useParamsStable } from "../../utils/hooks/use-params-stable";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { useNavigate } from "react-router-dom";
import { Options } from "./fields/options";
import { CheckBox } from "./fields/check-box";
import { MultipleFieldsAutocomplete } from "./fields/multiple-fields-autocomplete";
import { SingleFieldsAutocomplete } from "./fields/single-field-autocomplete";
import { FieldArrayLayout } from "./fields/field-array-layout";
import { SingleField } from "./fields/single-field";
import { LinkField } from "./fields/link-field";
import { ChoiceFieldAutocomplete } from "./fields/choice-field-autocomplete";
import { UserContext } from "../../utils/context/user-context";
import { EltrpPage } from "../../components/wrappers/eltrp-page";
import { EltrpBody } from "../../components/wrappers/eltrp-body";
import { EltrpHeader } from "../../components/wrappers/eltrp-header";
import { EltrpLoading } from "../../components/wrappers/eltrp-loading";

export const ArticleEditPage = () => {
  const id = useParamsStable("id");
  const [article, setArticle] = useState<Article>();
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [loadingIn, setLoadingIn] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setLoadingIn(true);
    id &&
      getArticle(id, context.handleError, setLoadingIn).subscribe((a) => {
        setLoadingIn(false);
        setArticle(a);
      });
  }, [id, context.handleError]);

  const submit = (updatedArticle: Article) => {
    updatedArticle = {
      ...updatedArticle,
      metadata: { ...updatedArticle.metadata },
    };
    setLoading(true);
    id &&
      article &&
      updateArticle(
        id,
        updatedArticle,
        context.handleError,
        setLoading,
      ).subscribe(() => {
        setLoading(false);
        navigate("/article/" + id);
      });
  };

  const initialValues = useMemo(() => {
    return {
      metadata: {
        ...article?.metadata,
        author:
          article?.metadata?.author && article?.metadata.author.length
            ? article?.metadata.author
            : [undefined],
        country:
          article?.metadata?.country && article?.metadata.country.length
            ? article?.metadata.country
            : [undefined],
        organization:
          article?.metadata?.organization &&
          article?.metadata.organization.length
            ? article?.metadata.organization
            : [undefined],
        periodical:
          article?.metadata?.periodical && article?.metadata.periodical.length
            ? article?.metadata.periodical
            : [undefined],
        series:
          article?.metadata?.series && article?.metadata.series.length
            ? article?.metadata.series
            : [undefined],
        conference:
          article?.metadata?.conference && article?.metadata.conference.length
            ? article?.metadata.conference
            : [undefined],
        link:
          article?.metadata?.link && article?.metadata.link.length
            ? article?.metadata.link
            : [undefined],
        identifier:
          article?.metadata?.identifier && article?.metadata.identifier.length
            ? article?.metadata.identifier
            : [undefined],
        contributor:
          article?.metadata?.contributor && article?.metadata.contributor.length
            ? article?.metadata.contributor
            : [undefined],
        relation:
          article?.metadata?.relation && article?.metadata.relation.length
            ? article?.metadata.relation
            : [undefined],
      },
    };
  }, [article]);

  return (
    <EltrpPage>
      <EltrpLoading loading={loadingIn} />
      <EltrpHeader title={`Article ${article?._id}`} />
      <EltrpBody>
        <section className="editForm">
          <Form
            onSubmit={submit}
            initialValues={initialValues}
            mutators={{ ...arrayMutators }}
            render={({
              handleSubmit,
              form: {
                mutators: { push },
              },
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="editGroup">
                  <CheckBox fieldName="metadata.published" label="Published" />
                </div>

                <div className="editGroup">
                  <SingleFieldsAutocomplete
                    fieldName="metadata.type"
                    label="Article Type"
                    termId="TYPE"
                    placeholder="Type of article"
                  />
                  <SingleFieldsAutocomplete
                    fieldName="metadata.language"
                    label="Language"
                    termId="LANGUAGE"
                    placeholder="Language of article"
                  />
                  <SingleFieldsAutocomplete
                    fieldName="metadata.year"
                    label="Publish Year"
                    termId="YEAR"
                    placeholder="Year of publication"
                  />
                </div>

                <div className="editGroup">
                  <FieldArrayLayout
                    textField={
                      <SingleField
                        fieldName="metadata.titleEn"
                        label="Title English"
                        placeholder="English title (original or translated by operator)"
                      />
                    }
                    button={
                      <CheckBox
                        fieldName="metadata.titleTranslated"
                        label="Translated"
                      />
                    }
                    name="titleEn"
                  />
                  <SingleField
                    fieldName="metadata.titleOther"
                    label="Title Other"
                    placeholder="Original title in other language (optional)"
                  />
                </div>

                <div className="editGroup">
                  <FieldArrayLayout
                    textField={
                      <Field name="metadata.abstractEn">
                        {({ input, meta }) => (
                          <TextField
                            size="small"
                            color="secondary"
                            InputLabelProps={{ shrink: true }}
                            maxRows={10}
                            multiline
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            label={"Abstract English"}
                            {...input}
                            placeholder="Abstract from article in english"
                          />
                        )}
                      </Field>
                    }
                    button={
                      <CheckBox
                        fieldName="metadata.abstractTranslated"
                        label="Translated"
                      />
                    }
                    name="abstractEn"
                  />
                  <Field name="metadata.abstractOther">
                    {({ input, meta }) => (
                      <TextField
                        size="small"
                        color="secondary"
                        InputLabelProps={{ shrink: true }}
                        maxRows={10}
                        multiline
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        label={"Abstract Other"}
                        {...input}
                        placeholder="Abstract from article in original language if not in english"
                      />
                    )}
                  </Field>
                  <Field name="metadata.summary">
                    {({ input, meta }) => (
                      <TextField
                        size="small"
                        color="secondary"
                        InputLabelProps={{ shrink: true }}
                        maxRows={10}
                        multiline
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        label={"Summary"}
                        {...input}
                        placeholder="Summary written by SDAB if abstract not available"
                      />
                    )}
                  </Field>
                </div>

                <div className="editGroup">
                  <SingleField
                    fieldName="metadata.commentExt"
                    label="Comment External"
                    placeholder="External comment"
                  />
                  <SingleField
                    fieldName="metadata.commentInt"
                    label="Comment Internal"
                    placeholder="Internal comment"
                  />
                </div>

                <div className="editGroup">
                  <FieldArray name="metadata.author">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <div key={name}>
                          <Field
                            name={name}
                            component="input"
                            subscription={{ active: true, value: true }}
                          >
                            {({ input, meta }) => (
                              <FieldArrayLayout
                                textField={
                                  <Autocomplete
                                    freeSolo
                                    options={Options(
                                      "AUTHOR",
                                      meta.active,
                                      input.value,
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        color="secondary"
                                        margin="normal"
                                        variant="outlined"
                                        label={
                                          "Author " + (index + 1).toString()
                                        }
                                        {...params}
                                        fullWidth
                                        placeholder="Family name, Given name "
                                        InputLabelProps={{ shrink: true }}
                                        size="small"
                                        value={input.value}
                                        onChange={(value) =>
                                          input.onChange(value)
                                        }
                                      />
                                    )}
                                    {...input}
                                    value={input.value}
                                    onChange={(event, value, reason) =>
                                      input.onChange(value)
                                    }
                                  />
                                }
                                button={
                                  <IconButton
                                    onClick={() => fields.remove(index)}
                                  >
                                    {" "}
                                    <Delete />{" "}
                                  </IconButton>
                                }
                                name="author"
                              />
                            )}
                          </Field>
                        </div>
                      ))
                    }
                  </FieldArray>
                  <Grid2 xs={12}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => push("metadata.author", undefined)}
                    >
                      {" "}
                      ADD NEW AUTHOR{" "}
                    </Button>
                  </Grid2>
                </div>

                <div className="editGroup">
                  <FieldArray name="metadata.country">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <div key={name}>
                          <Field
                            name={name}
                            component="input"
                            subscription={{ active: true, value: true }}
                          >
                            {({ input, meta }) => (
                              <FieldArrayLayout
                                textField={
                                  <Autocomplete
                                    freeSolo
                                    options={Options(
                                      "COUNTRY",
                                      meta.active,
                                      input.value,
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        color="secondary"
                                        margin="normal"
                                        variant="outlined"
                                        label={
                                          "Country " + (index + 1).toString()
                                        }
                                        {...params}
                                        fullWidth
                                        placeholder="Country of publication"
                                        InputLabelProps={{ shrink: true }}
                                        size="small"
                                        value={input.value}
                                        onChange={(value) =>
                                          input.onChange(value)
                                        }
                                      />
                                    )}
                                    {...input}
                                    value={input.value}
                                    onChange={(event, value, reason) =>
                                      input.onChange(value)
                                    }
                                  />
                                }
                                button={
                                  <IconButton
                                    onClick={() => fields.remove(index)}
                                  >
                                    {" "}
                                    <Delete />{" "}
                                  </IconButton>
                                }
                                name="country"
                              />
                            )}
                          </Field>
                        </div>
                      ))
                    }
                  </FieldArray>
                  <Grid2 xs={12}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => push("metadata.country", undefined)}
                    >
                      {" "}
                      ADD NEW COUNTRY{" "}
                    </Button>
                  </Grid2>
                </div>

                <div className="editGroup">
                  <Grid2 container>
                    <FieldArray name="metadata.subject">
                      {({ fields }) =>
                        fields.value
                          ? fields.value.map((name, index) => (
                              <Chip
                                label={name}
                                style={{ margin: "1% 1% 0% 0%" }}
                                key={index}
                                onDelete={() => fields.remove(index)}
                              />
                            ))
                          : undefined
                      }
                    </FieldArray>
                  </Grid2>
                  <Field
                    name="newSubject"
                    component="input"
                    subscription={{ active: true, value: true }}
                  >
                    {({ input, meta }) => (
                      <FieldArrayLayout
                        textField={
                          <Autocomplete
                            freeSolo
                            options={Options(
                              "SUBJECT",
                              meta.active,
                              input.value,
                            )}
                            renderInput={(params) => (
                              <TextField
                                color="secondary"
                                margin="normal"
                                variant="outlined"
                                label={"New Keyword"}
                                {...params}
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                placeholder=" Write or select keyword and press +"
                                value={input.value}
                                onChange={(value) => input.onChange(value)}
                              />
                            )}
                            {...input}
                            onChange={(event, value, reason) =>
                              input.onChange(value)
                            }
                          />
                        }
                        button={
                          <IconButton
                            onClick={() =>
                              push("metadata.subject", input.value)
                            }
                          >
                            {" "}
                            <Add />{" "}
                          </IconButton>
                        }
                        name="subject"
                      />
                    )}
                  </Field>
                </div>

                <div className="editGroup">
                  <FieldArray name="metadata.organization">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <div key={name}>
                          <Field
                            name={name}
                            component="input"
                            subscription={{ active: true, value: true }}
                          >
                            {({ input, meta }) => (
                              <FieldArrayLayout
                                textField={
                                  <MultipleFieldsAutocomplete
                                    input={input}
                                    label={["Organisation", "Unit"]}
                                    termId="ORGANIZATION_lead"
                                    leadId="ORGANIZATION"
                                    active={meta.active}
                                    placeholder={[
                                      "English name of organization (local language acronyme)",
                                      "Name of unit (optional)",
                                    ]}
                                  />
                                }
                                button={
                                  <IconButton
                                    onClick={() => fields.remove(index)}
                                  >
                                    {" "}
                                    <Delete />{" "}
                                  </IconButton>
                                }
                                name="organization"
                              />
                            )}
                          </Field>
                        </div>
                      ))
                    }
                  </FieldArray>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => push("metadata.organization", undefined)}
                  >
                    ADD NEW organisation
                  </Button>
                </div>

                <div className="editGroup">
                  <FieldArray name="metadata.periodical">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <div key={name}>
                          <Field
                            name={name}
                            component="input"
                            subscription={{ active: true, value: true }}
                          >
                            {({ input, meta }) => (
                              <FieldArrayLayout
                                textField={
                                  <MultipleFieldsAutocomplete
                                    input={input}
                                    label={["Periodical", "Sequential"]}
                                    termId="PERIODICAL_lead"
                                    leadId="PERIODICAL"
                                    active={meta.active}
                                    placeholder={[
                                      "Name of periodical",
                                      "(mandatory if known)",
                                    ]}
                                  />
                                }
                                button={
                                  <IconButton
                                    onClick={() => fields.remove(index)}
                                  >
                                    {" "}
                                    <Delete />{" "}
                                  </IconButton>
                                }
                                name="periodical"
                              />
                            )}
                          </Field>
                        </div>
                      ))
                    }
                  </FieldArray>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => push("metadata.periodical", undefined)}
                  >
                    ADD NEW periodical
                  </Button>
                </div>

                <div className="editGroup">
                  <FieldArray name="metadata.series">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <div key={name}>
                          <Field
                            name={name}
                            component="input"
                            subscription={{ active: true, value: true }}
                          >
                            {({ input, meta }) => (
                              <FieldArrayLayout
                                textField={
                                  <MultipleFieldsAutocomplete
                                    input={input}
                                    label={["Series", "Sequential"]}
                                    termId="SERIES_lead"
                                    leadId="SERIES"
                                    active={meta.active}
                                    placeholder={[
                                      "Name of series",
                                      "(mandatory if known)",
                                    ]}
                                  />
                                }
                                button={
                                  <IconButton
                                    onClick={() => fields.remove(index)}
                                  >
                                    {" "}
                                    <Delete />{" "}
                                  </IconButton>
                                }
                                name="series"
                              />
                            )}
                          </Field>
                        </div>
                      ))
                    }
                  </FieldArray>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => push("metadata.series", undefined)}
                  >
                    ADD NEW series
                  </Button>
                </div>

                <div className="editGroup">
                  <FieldArray name="metadata.conference">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <div key={name}>
                          <Field
                            name={name}
                            component="input"
                            subscription={{ active: true, value: true }}
                          >
                            {({ input, meta }) => (
                              <FieldArrayLayout
                                textField={
                                  <MultipleFieldsAutocomplete
                                    input={input}
                                    label={["Conference", "Sequential"]}
                                    termId="CONFERENCE_lead"
                                    leadId="CONFERENCE"
                                    active={meta.active}
                                    placeholder={[
                                      "Name of conference or meeting",
                                      "(mandatory if known)",
                                    ]}
                                  />
                                }
                                button={
                                  <IconButton
                                    onClick={() => fields.remove(index)}
                                  >
                                    {" "}
                                    <Delete />{" "}
                                  </IconButton>
                                }
                                name="conference"
                              />
                            )}
                          </Field>
                        </div>
                      ))
                    }
                  </FieldArray>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => push("metadata.conference", undefined)}
                  >
                    ADD NEW conference
                  </Button>
                </div>

                <div className="editGroup">
                  <FieldArray name="metadata.link">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <div key={name}>
                          <Field
                            name={name}
                            component="input"
                            subscription={{ active: true, value: true }}
                          >
                            {({ input, meta }) => (
                              <FieldArrayLayout
                                textField={
                                  <LinkField
                                    index={index}
                                    label={["Access", "URL", "Service"]}
                                    termId={["LINK_access", "LINK_service"]}
                                    input={input}
                                    active={meta.active}
                                    placeholder={[
                                      "(mandatory)",
                                      "Persistent URL link",
                                      "(optional)",
                                    ]}
                                  />
                                }
                                button={
                                  <IconButton
                                    onClick={() => fields.remove(index)}
                                  >
                                    {" "}
                                    <Delete />{" "}
                                  </IconButton>
                                }
                                name="link"
                              />
                            )}
                          </Field>
                        </div>
                      ))
                    }
                  </FieldArray>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => push("metadata.link", undefined)}
                  >
                    {" "}
                    ADD NEW URL{" "}
                  </Button>
                </div>

                <div className="editGroup">
                  <FieldArray name="metadata.identifier">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <div key={name}>
                          <Field name={name} component="input">
                            {({ input, meta }) => (
                              <FieldArrayLayout
                                textField={
                                  <ChoiceFieldAutocomplete
                                    input={input}
                                    label={["Type", "Identifier"]}
                                    termId="IDENTIFIER_type"
                                    leadId="IDENTIFIER_lead"
                                    active={meta.active}
                                    placeholder={[
                                      "Identification Type",
                                      "(mandatory)",
                                    ]}
                                  />
                                }
                                button={
                                  <IconButton
                                    onClick={() => fields.remove(index)}
                                  >
                                    {" "}
                                    <Delete />{" "}
                                  </IconButton>
                                }
                                name="identifier"
                              />
                            )}
                          </Field>
                        </div>
                      ))
                    }
                  </FieldArray>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => push("metadata.identifier", undefined)}
                  >
                    ADD NEW identifier
                  </Button>
                </div>

                <div className="editGroup">
                  <FieldArray name="metadata.contributor">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <div key={name}>
                          <Field
                            name={name}
                            component="input"
                            subscription={{ active: true, value: true }}
                          >
                            {({ input, meta }) => (
                              <FieldArrayLayout
                                textField={
                                  <ChoiceFieldAutocomplete
                                    input={input}
                                    label={["Contributor", "Type"]}
                                    termId="CONTRIBUTOR_type"
                                    leadId="CONTRIBUTOR_lead"
                                    active={meta.active}
                                    placeholder={[
                                      "Name of Contributor",
                                      "(mandatory)",
                                    ]}
                                  />
                                }
                                button={
                                  <IconButton
                                    onClick={() => fields.remove(index)}
                                  >
                                    {" "}
                                    <Delete />{" "}
                                  </IconButton>
                                }
                                name="contributor"
                              />
                            )}
                          </Field>
                        </div>
                      ))
                    }
                  </FieldArray>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => push("metadata.contributor", undefined)}
                  >
                    ADD NEW CONTRIBUTOR
                  </Button>
                </div>

                <div className="editGroup">
                  <FieldArray name="metadata.relation">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <div key={name}>
                          <Field
                            name={name}
                            component="input"
                            subscription={{ active: true, value: true }}
                          >
                            {({ input, meta }) => (
                              <FieldArrayLayout
                                textField={
                                  <MultipleFieldsAutocomplete
                                    input={input}
                                    label={[
                                      "Relation",
                                      "Sort Key (alphanumeric)",
                                    ]}
                                    termId="RELATION_lead"
                                    leadId="RELATION"
                                    active={meta.active}
                                    placeholder={["search-key", "(optional)"]}
                                  />
                                }
                                button={<></>}
                                name="relation"
                              />
                            )}
                          </Field>
                        </div>
                      ))
                    }
                  </FieldArray>
                </div>

                <Grid2>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    sx={{ mt: 1, mb: 1 }}
                  >
                    {" "}
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Submit"
                    )}{" "}
                  </Button>
                </Grid2>
              </form>
            )}
          />
        </section>
      </EltrpBody>
    </EltrpPage>
  );
};
