import { IosShare } from "@mui/icons-material";
import { useContext, useMemo, useState } from "react";
import { updateBasket } from "../components/save/save-article.service";
import { Alert, AlertColor, Box, Button, Snackbar } from "@mui/material";
import { UserContext } from "../utils/context/user-context";
import { EltrpModal } from "../components/modal/eltrp-modal";
import { getIdsFromLocalStorage } from "../utils/storage/local-storage";

interface BasketAlert {
  open: boolean;
  message: string;
  severity: AlertColor;
}

export const ShareBasket = () => {
  const [basketId, setBasketId] = useState<string>();
  const [open, setOpen] = useState<boolean>(false);
  const [alert, setAlert] = useState<BasketAlert>();
  const context = useContext(UserContext);

  const link = useMemo(() => {
    if (basketId) {
      return `https://eltrp.org/favourites/${basketId}`;
    }
  }, [basketId]);

  const copyContent = () => {
    if (basketId && link && navigator.clipboard) {
      navigator.clipboard
        .writeText(link)
        .then(() =>
          setAlert({
            open: true,
            message: "Copied content to clipboard",
            severity: "success",
          }),
        )
        .catch(() =>
          setAlert({
            open: true,
            message: `Error trying to copy content. Please copy manually.`,
            severity: "error",
          }),
        );
    } else if (basketId && link && !navigator.clipboard) {
      setAlert({
        open: true,
        message: `Error trying to copy content. Please copy manually.`,
        severity: "error",
      });
    }
  };

  const shareFavourites = () => {
    const ids = getIdsFromLocalStorage();
    ids &&
      updateBasket(ids, context.handleError).subscribe((res) => {
        setBasketId(res);
        setOpen(true);
      });
  };
  return (
    <Box mt={-1}>
      <Button
        startIcon={<IosShare />}
        onClick={shareFavourites}
        variant={"contained"}
        color={"secondary"}
      >
        Share favourite articles
      </Button>

      <EltrpModal
        title={"Share Favourite Articles"}
        open={open}
        handleClose={() => setOpen(false)}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={1}
          justifyContent={"center"}
          textAlign={"center"}
        >
          <Box>
            {" "}
            Copy the following link and share it! The link is valid for a year.
          </Box>
          <Box
            display={"flex"}
            gap={1}
            alignItems={"center"}
            justifyContent={"center"}
            overflow={"scroll"}
          >
            <a
              style={{ paddingLeft: 8 }}
              href={link}
              rel={"noreferrer"}
              target={"_blank"}
            >
              {link}
            </a>
            <Box sx={{ display: { xs: "none", md: "inline" } }}>
              <Button
                color={"secondary"}
                variant={"contained"}
                size={"small"}
                onClick={copyContent}
              >
                COPY LINK
              </Button>
            </Box>
          </Box>
        </Box>
      </EltrpModal>
      {alert && (
        <Snackbar
          autoHideDuration={alert.severity === "success" ? 6000 : undefined}
          open={alert?.open}
          onClose={() => setAlert(undefined)}
        >
          <Alert severity={alert.severity}>{alert?.message}</Alert>
        </Snackbar>
      )}
    </Box>
  );
};