import { PropsWithChildren } from "react";
import { PageHeaderContent } from "../content/header/page-header-content";
import { PageFooterContent } from "../content/footer/page-footer-content";

export const EltrpPage = (props: PropsWithChildren) => {
  const { children } = props;
  return (
    <div className="container">
      <header id={"top"}>
        <PageHeaderContent />
      </header>
      <main>{children}</main>
      <footer>
        <PageFooterContent />
      </footer>
    </div>
  );
};
