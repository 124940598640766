import { EltrpPage } from "../components/wrappers/eltrp-page";
import { EltrpHeader } from "../components/wrappers/eltrp-header";
import { EltrpBody } from "../components/wrappers/eltrp-body";
import { useContext, useEffect, useState } from "react";
import { getBasket } from "../components/save/save-article.service";
import { SearchResponse } from "../models/SearchResult";
import { ResultPagination } from "../search/pagination/result-pagination";
import { Link, useSearchParams } from "react-router-dom";
import { useParamsStable } from "../utils/hooks/use-params-stable";
import { AggregationsList } from "../search/aggregations/aggregations";
import { Box } from "@mui/material";
import { UserContext } from "../utils/context/user-context";
import { EltrpLoading } from "../components/wrappers/eltrp-loading";
import { EltrpSearchResults } from "../components/wrappers/eltrp-search-results";
import { ResultBox } from "../search/result-box";

export const BasketPage = () => {
  const [response, setResponse] = useState<SearchResponse>();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const context = useContext(UserContext);

  const basketId = useParamsStable("basketId");

  useEffect(() => {
    const from = searchParams.get("from") || "0";
    const size = searchParams.get("size") || "10";

    const filters = searchParams;
    const filtersAsArray: string[] = [];

    filters.forEach((value, key) =>
      key !== "from" && key !== "size"
        ? filtersAsArray.push([key, value].join(":"))
        : undefined,
    );

    if (basketId) {
      setLoading(true);
      getBasket(
        basketId,
        filtersAsArray,
        context.handleError,
        setLoading,
        from,
        size,
      ).subscribe((res) => {
        setResponse(res);
        setLoading(false);
      });
    }
  }, [searchParams, basketId, context.handleError]);
  const resultMessage = () => {
    const size = Number(searchParams.get("size"));
    const from = Number(searchParams.get("from"));
    const page =
      Number(searchParams.get("from")) /
        (Number(searchParams.get("size")) || 1) +
      1;

    return !response?.hits.total?.value || response?.hits.total.value === 0
      ? "No results"
      : "Showing " +
          (from + 1) +
          "-" +
          (response.hits?.total.value && size * page > response.hits.total.value
            ? response.hits?.total.value
            : size * (page | 1)) +
          " of " +
          response.hits?.total.value +
          " results";
  };

  return (
    <EltrpPage>
      <EltrpLoading loading={loading} />
      <EltrpHeader title={`Shared Articles`} />
      <EltrpBody>
        <EltrpSearchResults
          aggregations={
            <AggregationsList aggregations={response?.aggs || []} />
          }
          results={
            <Box mb={"2rem"}>
              <h2 style={{ marginBottom: 0 }}>Articles</h2>
              <Box display={"flex"} gap={1} flexDirection={"column"} mb={4}>
                {resultMessage()}
                {basketId && (
                  <i>
                    {" "}
                    These articles have been shared with you with the id{" "}
                    {basketId}. View your own favourite articles{" "}
                    <Link to={"/favourites"}> here</Link>.
                  </i>
                )}
              </Box>
              <ResultBox results={response?.hits.docs} />
              <ResultPagination value={response?.hits.total.value} />{" "}
            </Box>
          }
          hits={response?.hits.total.value || 0}
        />
      </EltrpBody>
    </EltrpPage>
  );
};
