import { Article } from "../models/Article";
import { Link, useSearchParams } from "react-router-dom";
import { useMemo, useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { ResultPagination } from "./pagination/result-pagination";
import { Typography } from "@mui/material";
import { ResultBox } from "./result-box";
import { MobileAggregations } from "./aggregations/mobile-aggregations";
import { ShareSearchResult } from "../components/share/share-search-result";

interface ISearchResultsProps {
  results?: {
    total: {
      value: number;
    };
    docs: Article[];
  };
  aggregations?: any;
}

export const SearchResults = ({
  results,
  aggregations,
}: ISearchResultsProps) => {
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState<number>(1);

  const searchResultMessage = useMemo(() => {
    const sortOrder = searchParams.get("sortOrder");
    if (sortOrder === "-PUBDATE") {
      return "Latest Publications";
    } else return "Search Result";
  }, [searchParams]);

  useMemo(
    () =>
      setPage(
        Number(searchParams.get("from")) /
          (Number(searchParams.get("size")) || 1) +
          1,
      ),
    [searchParams],
  );

  const resultMessage = () => {
    const size = Number(searchParams.get("size"));
    const from = Number(searchParams.get("from"));

    return !results
      ? ""
      : !results?.total?.value || results?.total.value === 0
        ? "No results for current search query"
        : "Showing " +
          (from + 1) +
          "-" +
          (results?.total.value && size * page > results.total.value
            ? results?.total.value
            : size * (page | 1)) +
          " of " +
          results?.total.value +
          " results";
  };

  return (
    <Grid2
      container
      justifyContent={{ xs: "center", md: "left" }}
      direction="column"
      maxWidth={"900px"}
      ml={{ md: "2rem" }}
      mb={"2rem"}
    >
      <Grid2 container sx={{ display: { xs: "none", md: "flex" } }} mb={4}>
        <Grid2 xs={12}>
          <h2> {searchResultMessage} </h2>
        </Grid2>
        <Grid2 xs={12} display={"flex"} justifyContent={"space-between"}>
          {resultMessage()}
          {results && results?.total?.value !== 0 && <ShareSearchResult />}
        </Grid2>
        <Grid2 xs={12}>
          {results?.total.value === 0 ? (
            <span>
              <h3>
                {" "}
                Do you know any articles related to the query "
                {searchParams.get("query")?.trim()}"?
              </h3>
              <p>
                Try suggesting them to ELTRP! <br />
                Reach out to us with your suggestion!
              </p>
              <p>
                Read more under our <Link to={"/contact"}> contact page</Link>.
              </p>
            </span>
          ) : (
            <></>
          )}
        </Grid2>
      </Grid2>
      <Grid2
        xs={12}
        container
        sx={{ display: { xs: "flex", md: "none" } }}
        mb={4}
      >
        <Grid2 xs={8}>
          <h2 style={{ marginBottom: "0" }}> {searchResultMessage} </h2>
          <Typography variant={"subtitle2"}> {resultMessage()}</Typography>
        </Grid2>

        {results?.total.value !== 0 && (
          <Grid2
            display={"flex"}
            xs={4}
            justifyContent={"right"}
            alignItems={"center"}
          >
            <MobileAggregations aggregations={aggregations} />
          </Grid2>
        )}

        {results?.total.value === 0 ? (
          <span>
            <h3>
              {" "}
              Do you know any articles related to the query "
              {searchParams.get("query")?.trim()}"?
            </h3>
            <p>
              Try suggesting them to ELTRP! <br />
              Reach out to us with your suggestion!
            </p>
            <p>
              Read more under our <Link to={"/contact"}> contact page</Link>.
            </p>
          </span>
        ) : (
          <></>
        )}
      </Grid2>
      <div
        className="searchResult"
        style={{ display: results?.total.value ? "inline" : "none" }}
      >
        <ResultBox results={results?.docs} sharable={true} />
        <ResultPagination value={results?.total.value} />
      </div>
    </Grid2>
  );
};