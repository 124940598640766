import { Alert, Snackbar } from "@mui/material";
import { useContext } from "react";
import { UserContext } from "../../utils/context/user-context";

export const ErrorBar = () => {
  const context = useContext(UserContext);

  return (
    <Snackbar
      open={context.snackbar.open}
      autoHideDuration={context.snackbar.persistent ? null : 6000}
      onClose={() =>
        context.handleSnackbar({
          message: "",
          extra: null,
          statusText: "",
          open: false,
        })
      }
    >
      <Alert
        severity="error"
        sx={{ width: "100%" }}
        onClose={() =>
          context.handleSnackbar({
            message: "",
            extra: null,
            statusText: "",
            open: false,
          })
        }
      >
        <b> {context.snackbar.message} </b> <br />
        <>
          {" "}
          {context.snackbar.statusText} <br />
          {context.snackbar.extra
            ? JSON.stringify(context.snackbar.extra, null, 2)
            : null}{" "}
        </>
      </Alert>
    </Snackbar>
  );
};
